.container {
    display: flex;
    flex-direction: column; /* Cambiamos la dirección a columna */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    height: 100vh; /* Ajusta la altura del contenedor según tus necesidades */
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #4068ef;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
    margin-bottom: 10px; /* Añadimos margen inferior al spinner */
}

.loader:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border: 5px solid #4068ef;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
}

.loading-text {
    font-size: 16px; /* Ajusta el tamaño del texto según tus necesidades */
    color: #000000; /* Color del texto */
}
